import React from "react";
import { Layout } from "../components";
import styled from "styled-components";
import { animated, Spring, config } from "react-spring";

const TextWrapper = styled.div`
  padding: 2rem 1.5rem;
  max-width: 55rem;
  margin: 0 auto;
  margin-bottom: 1rem;

  p {
    font-size: 18px;
  }

  img {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const ContactPage = () => (
  <Layout>
    <Spring
      native
      config={config.slow}
      delay={750}
      from={{ opacity: 0, transform: "translate3d(0, -30px, 0)" }}
      to={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
    >
      {(props) => (
        <animated.div style={props}>
          <TextWrapper>
            <h1>Kontakt</h1>
            <div class='contactbox' markdown='1'>
              <div class='contact-head' markdown='1'>
                Michael Steeger
              </div>
              <div class='contact-subhead' markdown='1'>
                Büro für Grafikdesign und kreative Markenideen
              </div>
              <div class='tip' markdown='1'>
                Fleher Straße 149
              </div>
              <div class='city' markdown='1'>
                40223 Düsseldorf
              </div>
              <div class='link' markdown='1'>
                Telefon: <a href='tel:02111754870'>0211 1754870</a>
              </div>
              <div class='link' markdown='1'>
                Mobil: <a href='tel:01637913347'>0163 7913347</a>{" "}
              </div>
              <div class='link' markdown='1'>
                Mail:{" "}
                <a href='mailto:michaelsteeger@yahoo.com'>
                  michaelsteeger@yahoo.com
                </a>{" "}
              </div>
            </div>
            <p>
              Individuelle Portfolios zu speziellen Themen wie z. B.
              Webgestaltung, Apps, Corporate oder Editorial und eine
              Honorarübersicht versende ich gerne auf Anfrage.
            </p>
          </TextWrapper>
        </animated.div>
      )}
    </Spring>
  </Layout>
);

export default ContactPage;
